import { useUserStore } from '@/stores/userStore'

export default defineNuxtRouteMiddleware(async (to) => {
  const userStore = useUserStore()

  if (!userStore.isAuthenticated) {
    const url = useRequestURL()
    const redirectUrl = `${url.origin}${to.fullPath}`

    return await userStore.signIn({ redirectUrl, method: '' })
  }

  if (!userStore.user?.email.includes('@lahaus.com')) {
    return navigateTo('/', { external: true })
  }
})
